<template>
  <div class="UserDialog">
    <q-dialog v-model="dialog" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">{{ message }}</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input label="Nome" autofocus v-model="name" />
          <q-input label="E-mail" :disable="!isInclusion" v-model="email" />
          <q-input label="Usuário Protheus" v-model="protheusUserCode" />
          <q-checkbox label="É administrador?" v-model="isAdministrator" />
          <br />
          <q-checkbox label="Ativo?" v-model="isActive" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="Fechar" color="red" v-close-popup />
          <q-btn flat label="Confirmar" color="green" @click="confirm" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      isInclusion: true,
      id: "",
      name: "",
      email: "",
      protheusUserCode: "",
      isAdministrator: false,
      isActive: false,
      message: "Novo usuário"
    };
  },
  methods: {
    ...mapMutations("shared", ["setNotification"]),
    openDialog(user) {
      if (user) {
        this.isInclusion = false;
        this.message = "Alteração de usuário";
        this.id = user.id;
        this.name = user.name;
        this.email = user.email;
        this.protheusUserCode = user.protheusUserCode;
        this.isAdministrator = user.isAdministrator;
        this.isActive = user.isActive;
      } else {
        this.id = "";
        this.name = "";
        this.email = "";
        this.protheusUserCode = "";
        this.isAdministrator = false;
        this.isActive = false;
      }
      this.dialog = true;
    },
    confirm() {
      const user = {
        id: this.id,
        name: this.name,
        email: this.email,
        protheusUserCode: this.protheusUserCode,
        isAdministrator: this.isAdministrator,
        isActive: this.isActive
      };
      if (
        !user.name.trim() ||
        !user.email.trim() ||
        !user.protheusUserCode.trim()
      ) {
        this.setNotification({
          message: "Preencha Nome, e-mail e código de usuário do Protheus",
          color: "red",
          position: "top"
        });
        return;
      }
      this.dialog = false;

      this.$emit("confirmDialog", user);
    }
  }
};
</script>
