<template>
  <div class="userRegistration">
    <div class="q-mt-lg q-px-lg full-width">
      <DataTable
        :columns="columns"
        :data="filteredUsers"
        actionButtonLabel="Novo usuario"
        actionButtonColor="green"
        actionButtonIcon="person_add"
        @actionButtonClick="openUserDialog()"
        @rowClick="userRowClick"
        @filter="filterByName"
        filterData
      />
    </div>

    <UserDialog ref="userDialog" @confirmDialog="confirmUserDialog" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DataTable from "../../components/DataTable";
import UserDialog from "../../components/UserDialog";
export default {
  components: {
    DataTable,
    UserDialog
  },
  data() {
    return {
      columns: [
        {
          field: "name",
          label: "Nome",
          align: "left",
          sortable: true
        },
        {
          field: "isAdministrator",
          label: "É administrador?",
          align: "center",
          badge: [
            { value: true, text: "Sim", color: "yellow-9" },
            { value: false, text: "Não", color: "blue" }
          ],
          sortable: true
        },
        {
          field: "isActive",
          label: "Usuário ativo?",
          align: "center",
          badge: [
            { value: true, text: "Sim", color: "green" },
            { value: false, text: "Não", color: "red" }
          ],
          sortable: true
        },
        {
          field: "email",
          label: "E-mail",
          align: "left",
          sortable: true
        },
        {
          field: "protheusUserCode",
          label: "Usuario Protheus",
          align: "left",
          sortable: true
        }
      ],
      filter: null
    };
  },
  computed: {
    ...mapState("users", ["users"]),
    filteredUsers() {
      if (!this.filter || this.filter.trim().length <= 0) {
        return this.users;
      }

      return this.users.filter(
        user =>
          user.name
            .toUpperCase()
            .trim()
            .indexOf(this.filter.toUpperCase().trim()) >= 0
      );
    }
  },
  methods: {
    ...mapActions("users", ["getUsers", "manageUser"]),
    openUserDialog(user) {
      this.$refs.userDialog.openDialog(user);
    },
    confirmUserDialog(user) {
      this.manageUser(user);
    },
    userRowClick(user) {
      this.$refs.userDialog.openDialog(user);
    },
    filterByName(filter) {
      this.filter = filter;
    }
  },
  mounted() {
    this.getUsers();
  }
};
</script>

<style lang="scss"></style>
